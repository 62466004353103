import React from "react";
import classNames from "classnames";
import { Container, Text, Icon, Image } from "@atoms";

const component = ({ heading, descriptor, benefits, color, graphic }) => {
  const background = {
    teal: "bg-teal",
    darkTeal: "bg-teal-dark",
    pink: "bg-pink",
    purple: "bg-purple",
  }[color];

  return (
    <Container>
      <div
        className={classNames(
          "flex flex-col items-center gap-10 rounded-lg p-5 shadow-lg sm:p-8 md:gap-16 md:p-12",
          background
        )}
      >
        <div
          className={classNames("flex max-w-xl flex-col gap-5 text-center", {
            "text-white": color !== "teal",
          })}
        >
          <Text variant="h2">{heading}</Text>
          <Text variant="lg">{descriptor}</Text>
        </div>
        <div className="flex flex-wrap gap-10 md:gap-20">
          {!!benefits?.length && (
            <div className="order-2 flex flex-1 flex-col gap-5 md:order-1">
              {benefits.map(benefit => {
                const {
                  uid,
                  heading: subheading,
                  descriptor: subdescriptor,
                } = benefit;
                return (
                  <div key={uid} className="flex gap-2">
                    <div className="flex-shrink-0 pt-0.5">
                      <Icon
                        name="checkmark"
                        className={classNames("h-4 w-4", {
                          "text-pink": color !== "pink",
                          "text-teal-light": color === "pink",
                        })}
                      />
                    </div>
                    <div
                      className={classNames("flex flex-col gap-2", {
                        "text-white": color !== "teal",
                      })}
                    >
                      <Text className="font-heading font-bold leading-tight">
                        {subheading}
                      </Text>
                      <Text variant="xs">{subdescriptor}</Text>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="relative order-1 flex w-full items-center justify-center md:order-2 md:w-1/2">
            {graphic && (
              <div className="relative flex w-3/4 items-center justify-center overflow-visible md:absolute md:top-0 md:left-0 md:w-[130%] lg:-left-[10%] lg:-top-[27.5%] lg:w-[145%] xl:left-0">
                <Image image={graphic} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

component.defaultProps = {};

export default component;
